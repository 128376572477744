<template>
	<div>
		<a-form-model ref="step" :model="step4">
			<a-card class="mt-3">
				<a-tooltip slot="title" overlayClassName="change-tooltip-color">
					<template slot="title">
						<p class="mb-0" v-if="instance.productType === 'lowrise'">
							Lot Premiums: ${{ formatPrice(lotPremiums) }}<br />
							Model Price: ${{ formatPrice(pkgPrice) }}
						</p>
						<p class="mb-0" v-if="instance.productType === 'highrise'">
							Unit Premiums: ${{ formatPrice(unitPremiums) }}<br />
							Unit Price: ${{ formatPrice(pkgPrice) }}<br />
						</p>
					</template>
					<div style="display:inline-block">Suggested Unit Price (MSRP): ${{ msrp }}</div>
				</a-tooltip>
				<a-row :gutter="16">
					<a-col :span="6">
						<a-form-model-item class="imp-label" label="Purchase price (Offer)" prop="purchasePrice"
							:rules="req('Please input the Purchase price')">
							<a-input-number :disabled="true" style="width:300px; max-width:100%;"
								v-model="step4.purchasePrice" :formatter="value => '$ ' + formatPrice(value)"
								:parser="value => value.replace(/\$\s?|(,*)/g, '')" />
						</a-form-model-item>
					</a-col>
				</a-row>

				<a-form-model-item :rules="req('Please input the Reservation Fee')">
					<template slot="label">
						Reservation Fee ({{defaultReservationCurrency}})
						<a-tooltip overlayClassName="change-tooltip-color">
							<template slot="title">
								Please connect to stripe account in order to receive reservation payment online. <a :href="`https://inventory.bildhive.${$tld}/settings?payments-connection=true`" target="_blank">Click here</a> to connect.
							</template>
							<a-icon type="question-circle" style="font-size: 14px; color: black; margin-left: 2px;" />
						</a-tooltip>
					</template>
					<a-input-number style="width:300px; max-width:100%;" v-model="step4.reservationAmount"
						:formatter="value => '$ ' + formatPrice(value)" />
				</a-form-model-item>
			</a-card>
			<a-card title="Custom Fields" v-if="customFields.length" class="mt-3">
				<a-form-model-item v-for="(item, index) in customFields" :help="item.help ? item.help : ''"
					:key="index + item.value" :label="item.name || 'Unnamed Question'"
					:val="`step4.customFields['${item.value}']`">
					<template v-if="item.type === 'text'">
						<a-textarea v-model="step4.customFields[item.value.replace('other.customFields.', '')]" :auto-size="{ minRows: 3, maxRows: 5 }"/>
					</template>

					<template v-else-if="item.type === 'checkmark'">
						<a-switch v-model="step4.customFields[item.value.replace('other.customFields.', '')]" />
					</template>
					<template v-else-if="item.type === 'multiple'">
						<a-select :value="step4.customFields[item.value.replace('other.customFields.', '')]"
							@change="(e) => selectMultiple(e, item.value.replace('other.customFields.', ''))">
							<a-select-option v-for="opt in item.configure.options" :key="opt.value" :value="opt.value">{{
								opt.value }}</a-select-option>
						</a-select>
					</template>
					<template v-else-if="item.type === 'image'">
						<ImageBoxSelector v-model="step4.customFields[item.value.replace('other.customFields.', '')]" />
					</template>
					<template v-else-if="item.type === 'date'">
						<a-date-picker placeholder="Choose Date (eg. 2025-03-31)"
							v-model="step4.customFields[item.value.replace('other.customFields.', '')]"
							:disabled-date="disabledDate" style="width: 100%;" valueFormat="YYYY/MM/DD"></a-date-picker>
					</template>
					<template v-else-if="item.type === 'inclusion'">
						<a-select mode="multiple"
							v-model="step4.customFields[item.value.replace('other.customFields.', '')]">
							<a-select-option v-for="(inclusion, index) in inclusions" :key="inclusion.value + index"
								:value="inclusion.body" :label="inclusion.label">{{ inclusion.label }}</a-select-option>
						</a-select>
					</template>
					<template v-else-if="item.type === 'variable'">
						<div v-html="item.configure.defaultValue"></div>
						<div class="mt-3 px-3">
							<a-form-model-item :label="'Exclude Field from Contract'" style="margin-bottom: 0.5rem;">
								<a-switch @change="excludeField(item)" v-model="item.hide"></a-switch>
							</a-form-model-item>
							<a-form-model-item v-for="(variable, index) in step4.customFieldVariables[item.value.replace('other.customFields.', '')]" :label="variable.label || 'Unnamed Variable'" style="margin-bottom: 0.5rem;" :key="variable+index">
								<a-input :defaultValue="variable.value" @change="(e) => updateVariable(e, item.value.replace('other.customFields.', ''), index)" :disabled="item.hide" />
							</a-form-model-item>
						</div>
					</template>
				</a-form-model-item>
			</a-card>
		</a-form-model>
	</div>
</template>

<script>
import { generateContractPreview, isProductAvailable, getContractTemplateName } from './tools'
import { setProp } from 'bh-mod'

export default {
	components: {},
	props: ['reservation'],
	data: () => ({
		finalReservation: {},
		customFields: {},
		envelopeFileKey: null,
		step4: {
			package: {},
			customFields: {},
			purchasePrice: 0,
			reservationAmount: 0,
			customFieldVariables: {}
		},
	}),
	computed: {
		defaultReservationAmount() {
			return this.$store.state.appData.reservationAmount || 0
		},
		defaultReservationCurrency() {
			return this.$store.state.appData.reservationCurrency || 'USD'
		},
		unitPremiums() {
			let unitPremiums = this.reservation.product.unit && this.reservation.product.unit.package.other && this.reservation.product.unit.package.other.premiums || {}
			let price = 0
			Object.values(unitPremiums).forEach(prm => {
				if (!prm.active) return
				if (prm.value) prm.value = +prm.value
				if (isNaN(prm.value)) return
				price += prm.value
			})
			return price
		},
		lotPremiums() {
			let lotP = 0
			if (this.reservation.product.lot.other && this.reservation.product.lot.other.premiums && Object.keys(this.reservation.product.lot.other.premiums).length) {
				Object.values(this.reservation.product.lot.other.premiums).forEach(item => {
					if (item.active) {
						lotP += item.price
					}
				})
			}

			return lotP
		},
		pkgPrice() {
			return this.reservation.product.unit.package.price || 0
		},
		msrp() {
			if (this.instance.productType === 'highrise') {
				return (this.pkgPrice + this.unitPremiums)
			} else {
				return (this.pkgPrice + this.lotPremiums).toLocaleString('en-US')
			}
		},

		instance() {
			return this.$store.state.instance
		},
		returningToStep() {
			return Object.keys(this.reservation.offer).length || Object.keys(this.reservation.other).length
		},
		currentContract() {
			if (!this.reservation.contract) return {}
			return this.$store.state.appData.templates[this.reservation.template] || {}
		},
		country() {
			let { country = "" } = this.instance;
			if (!country) country = "";
			country = country.toUpperCase().trim();
			const countryList = {
				CA: "Canada",
				US: "United States",
			}

			return countryList[country] || country;
		},
		address() {
			const obj = {
				municipal: '',
				municipal2: '',
				city: '',
				region: '',
				postal: '',
				postal2: '',
				legalDescription: '',
				country: this.country,
			}

			let productDetails = {}

			if (this.instance.productType === 'highrise') {
				productDetails = this.reservation.product.unit;
			} else {
				productDetails = this.reservation.product.lot;
			}

			obj.city = productDetails.city;
			obj.region = productDetails.region;
			obj.postal = productDetails.postal;
			obj.postal2 = productDetails.postal2 || '';
			obj.legalDescription = productDetails.legalDescription;

			if (productDetails.streetNumber) {
				obj.municipal += productDetails.streetNumber;
			}
			if (productDetails.street) {
				obj.municipal += ' ' + productDetails.street;
			}
			if (productDetails.city) {
				obj.municipal += ', ' + productDetails.city;
			}
			if (productDetails.region) {
				obj.municipal += ', ' + productDetails.region;
			}
			if (productDetails.postal) {
				obj.municipal += ', ' + productDetails.postal;
			}

			if (productDetails.streetNumber2) {
				obj.municipal2 += productDetails.streetNumber2;
			}
			if (productDetails.street2) {
				obj.municipal2 += ' ' + productDetails.street2;
			}
			if (productDetails.city) {
				obj.municipal2 += ', ' + productDetails.city;
			}
			if (productDetails.region) {
				obj.municipal2 += ', ' + productDetails.region;
			}
			if (productDetails.postal2) {
				obj.municipal2 += ', ' + productDetails.postal2;
			}

			return obj;
		}
	},
	methods: {
		excludeField(item){
			if (item.hide){
				this.step4.customFields[item.value.replace('other.customFields.', '')] += '%hide%';
			} else {
				this.step4.customFields[item.value.replace('other.customFields.', '')] = this.step4.customFields[item.value.replace('other.customFields.', '')].replaceAll('%hide%', '');
			}
		},
		updateVariable(e, key, index){
			this.step4.customFieldVariables[key][index].value = e.target.value;
		},
		getVariableNames(input) {
			const matches = input.match(/{(.*?)}/g);
			return [...new Set(matches.map(match => match.slice(1, -1)))];
		},
		req: msg => ({ required: true, message: msg }),

		calculateTotalPurchase() {
			this.step4.reservationAmount = this.defaultReservationAmount;
			this.step4.purchasePrice = this.step4.package.price
		},

		selectMultiple(e, i) {
			this.step4.customFields[i] = e
		},

		async createEnvelope() {
			this.$store.commit('LOAD', true)
			let reservation = this.finalReservation

			reservation.envelope = {
				name: getContractTemplateName(reservation, this.instance.productType) || this.currentContract.name,
				isManual: true,
				file: {
					template: this.currentContract.id,
					key: this.envelopeFileKey
				}
			}

			if (this.step4.purchasePrice) {
				reservation.price = this.step4.purchasePrice
			}

			let url = '/reservations/:instance'
			if (this.$route.params.id && reservation.id) {
				reservation.resend = true
				url = `/reservations/:instance/${reservation.id}`

				if (reservation.envelopes) {
					let mainEnvelope = reservation.envelopes.find(x => x.main)
					if (mainEnvelope) {
						reservation.envelope.id = mainEnvelope.id
						delete reservation.envelopes
					}
				}
			}

			if(this.instance.productType === 'lowrise') {
				reservation.lot = reservation.product.lot.id
				reservation.unit = reservation.product.unit.id
			} else {
				reservation.floor = reservation.product.floor.id
				reservation.unit = reservation.product.unit.id
			}

			reservation.amount = reservation.offer.reservationAmount;

			this.$api[this.$route.params.id && reservation.id ? 'put' : 'post'](url, reservation)
				.then(resp => {
					if (resp && resp.data && resp.data.id) {
						let reservation = resp.data
						this.$router.push(`/reservation/${reservation.id}?v=001`)
						this.$message.success('Transaction Created. Please remember to download and print')
					}
				}).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, `An error occurred while creating your reservation. Please try again.`))
					}
				}).finally(() => {
					this.$store.commit('LOAD', false)
					this.$store.commit('CLOSE_PREVIEW')

				})
		},
		async generatePreview() {
			if (this.$store.state.savingAsDraft) {
				this.$emit('done', { ...this.finalReservation })
				return
			}
			const reservation = this.finalReservation
			this.$store.commit('LOAD', true)
			if (!isProductAvailable(reservation, this)) {
				if (this.instance.productType == 'lowrise') {
					this.$message.error('Sorry this lot is no longer available. Please select another one.')
				} else {
					this.$message.error('Sorry this unit is no longer available. Please select another one.')
				}
				return
			}

			try {
				const { key, url } = await generateContractPreview(reservation, this)
				this.envelopeFileKey = key
				this.$store.commit('REVIEW_SEND', { preview: url, callback: this.createEnvelope, button: 'DOWNLOAD', isManual: true })
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'An error occurred while generating your contract. Please try again.'))
				}
			}

			this.$store.commit('LOAD', false)

		},

		formatPrice: (p) => {
			let isDot = false
			if (typeof p === 'string') {
				if (isNaN(+p[p.length - 1])) {
					if (p[p.length - 1] === '.') {
						isDot = true
					}
				}
				if (p[p.length - 4] === '.') {
					p = p.split('')
					p.pop()
					p = p.join('')
				}
				p = parseFloat(parseFloat(p).toFixed(2))
			}
			if (!p) return `0`
			let result = p.toLocaleString('en-us')
			if (isDot) result = result + '.'
			return result
		},
		validate() {
			if (this.depositClosingIssue || this.step4.balanceDue < 0) return this.$message.error('Please review the fields')

			let {
				purchasePrice,
				reservationAmount,
				package: pkg,
				customFields = {},
				customFieldVariables,
			} = this.step4

			pkg.other = pkg.other || {};
			this.movedOn = true

			this.reservation.offer.basePrice = this.step4.package.price
			this.reservation.offer.purchasePrice = this.step4.purchasePrice
			this.reservation.product.unit.package.price = this.step4.package.price

			if (this.reservation.isManual) {
				let reservation = JSON.parse(JSON.stringify(this.reservation))

				if (this.$store.state.appData.allSettings && this.$store.state.appData.allSettings.app && this.$store.state.appData.allSettings.app.options) {
					reservation.other.defaults = this.$store.state.appData.allSettings.app.options
				}

				let { package: pkg, purchasePrice, unitPrice, reservationAmount } = this.step4
				reservation.offer.basePrice = pkg.price
				reservation.offer.purchasePrice = purchasePrice
				reservation.offer.reservationAmount = reservationAmount
				reservation.product.unit.package.price = unitPrice
				if (typeof pkg === 'object' && this.reservation.product.unit && this.reservation.product.unit.packages) {
					reservation.product.unit.package = JSON.parse(JSON.stringify(pkg))
					reservation.product.unit.packages[0] = JSON.parse(JSON.stringify(pkg))
				}
				if (pkg && pkg.sqft2 && pkg.sqft2 > 0) {
					reservation.product.unit.package.sqft = `${pkg.sqft} plus ${pkg.sqft2}`
				}

				reservation.other.address = this.address
				reservation.other.customFields = customFields
				reservation.other.customFieldVariables = customFieldVariables

				this.finalReservation = reservation
				this.generatePreview()
			} else {
				let pkgCopy = JSON.parse(JSON.stringify(pkg))
				if (pkg && pkg.sqft2 && pkg.sqft2 > 0) {
					pkgCopy.sqft = `${pkg.sqft} plus ${pkg.sqft2}`
				}
				let sendObj = {
					package: pkgCopy,
					offer: {
						purchasePrice,
						reservationAmount
					},
					other: {
						customFields,
						customFieldVariables,
						address: this.address
					}
				}
				this.$emit('done', sendObj)
			}
		}
	},
	mounted() {
		this.$emit('setFn', this.validate)

		let cF = this.currentContract.customFields || {}
		let customFields = {}
		let { pages = [] } = this.currentContract

		pages.forEach(page => {
			let { fields = [] } = page
			fields.forEach(spot => {
				let { field, text } = spot
				if (field.custom && cF[field.value] && !customFields[field.value]) {
					customFields[field.value] = cF[field.value]

					let fieldKey = field.value.replace('other.customFields.', '')

					if (field.type == 'variable' && cF[field.value]?.configure?.defaultValue != '') {
						let variables = this.getVariableNames(cF[field.value]?.configure?.defaultValue)
						variables = variables.map((x,xI) => {
							const value = ((this.returningToStep || this.$route.query.draft) && this.step4.customFieldVariables && this.step4.customFieldVariables[fieldKey] && this.step4.customFieldVariables[fieldKey][xI]?.value) || cF[field.value]?.configure?.options[xI]?.value
							return {
								label: x,
								value: value
							}
						})
						setProp(this.step4.customFieldVariables, [fieldKey], variables)
					}
					if (field.type == 'multiple' && cF[field.value].configure && cF[field.value].configure.defaultValue != '') {
						const value = ((this.returningToStep || this.$route.query.draft) && this.step4.customFields && this.step4.customFields[fieldKey]) || cF[field.value].configure.options[parseInt(cF[field.value].configure.defaultValue)].value
						setProp(this.step4.customFields, [fieldKey], value)
					} else {
						const value = ((this.returningToStep || this.$route.query.draft) && this.step4.customFields && this.step4.customFields[fieldKey]) || (cF[field.value] && cF[field.value].configure && cF[field.value].configure.defaultValue)
						setProp(this.step4.customFields, [fieldKey], value)
					}
				}
			})
		})
		this.customFields = Object.values(customFields)
	},

	created() {
		if (this.returningToStep) {
			let {
				other = {
					customFields: {},
					customFieldVariables: {}
				}
			} = this.reservation

			let { customFields, customFieldVariables } = other

			this.step4.customFields = customFields || this.step4.customFields || {};
			this.step4.customFieldVariables = customFieldVariables || this.step4.customFieldVariables,
			this.step4.package = JSON.parse(JSON.stringify(this.reservation.product.unit.package))
		}

		this.calculateTotalPurchase()
	}
}
</script>

<style lang="scss" scoped>
a {
	color: var(--primary) !important;
	text-decoration: none;
}
</style>
