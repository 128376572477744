<template>
    <div>
        <a-form-model ref="step" :model="step4">
            <a-card
                title="Inclusions"
                v-if="questions.inclusions && inclusions.length"
            >
                <a-form-model-item prop="inclusions" label="Choose Inclusions">
                    <a-checkbox-group
                        v-model="step4.inclusions"
                        :options="inclusions"
                    />
                </a-form-model-item>
            </a-card>

            <a-card
                class="mt-3"
                v-if="
                    transaction.type === 'purchase' &&
                    instance.productType === 'highrise'
                "
            >
                <a-tooltip overlayClassName="change-tooltip-color" slot="title">
                    <template slot="title">
                        <p class="mb-0">
                            Unit Premiums: ${{ formatPrice(unitPremiums)
                            }}<br />
                            Unit AddOns: ${{ formatPrice((transaction.type == 'purchase' ? step4.addOnsPrice : step4.addOnsRent)) }}<br />
                            Unit Price: ${{ formatPrice(pkgPrice) }}
                        </p>
                    </template>
                    <div style="display: inline-block">MSRP: ${{ msrp }}</div>
                </a-tooltip>
            </a-card>

            <a-card class="mt-3" v-if="transaction.type === 'purchase' && instance.productType === 'highrise'">
                <a-row :gutter="16">
                    <a-col :span="6">
                        <a-form-model-item
                            class="imp-label"
                            label="Purchase price (Add Ons Offer)"
                            prop="addOnsPrice"
                            :rules="req('Please input the addOnsPrice price')"
                        >
                            <a-input-number
                                style="width: 300px; max-width: 100%"
                                v-model="step4.addOnsPrice"
                                :formatter="
                                    (value) => '$ ' + formatPrice(value)
                                "
                                :parser="
                                    (value) => value.replace(/\$\s?|(,*)/g, '')
                                "
                                @change="calculateAddOnDeposits"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-card>

			<a-card class="mt-3" v-if="transaction.type === 'lease' && instance.productType === 'highrise'">
                <a-row :gutter="16">
                    <a-col :span="6">
                        <a-form-model-item
                            class="imp-label"
                            label="Rent price (Add Ons Offer)"
                            prop="addOnsRent"
                            :rules="req('Please input the addOnsRent price')"
                        >
                            <a-input-number
                                style="width: 300px; max-width: 100%"
                                v-model="step4.addOnsRent"
                                :formatter="
                                    (value) => '$ ' + formatPrice(value)
                                "
                                :parser="
                                    (value) => value.replace(/\$\s?|(,*)/g, '')
                                "
                                @change="calculateAddOnDeposits"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-card>

            <a-card
                class="mt-3"
                title="Add Ons Deposit Structure"
                v-if="
                    questions.addOnDeposits > 0 &&
                    transaction.type == 'purchase' &&
                    step4.addOnDeposits.length
                "
            >
                <a-row :gutter="16">
                    <a-col :span="2">
                        <a-form-model-item :label="`#`" class="mb-0">
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq === 'lg' ? 6 : 10">
                        <a-form-model-item
                            :label="`Add On Deposit Date`"
                            class="mb-0"
                        >
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-model-item
                            :label="`Add On Deposit Amount`"
                            class="mb-0"
                        >
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row
                    :gutter="16"
                    v-for="i in questions.addOnDeposits"
                    :key="i"
                    class="mb-2"
                >
                    <a-col :span="2">
                        <a-form-model-item
                            :prop="`addOnDeposits.${i - 1}.date`"
                            class="mb-0"
                        >
                            <a-input disabled :value="i" />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq === 'lg' ? 6 : 10">
                        <a-form-model-item
                            :prop="`addOnDeposits.${i - 1}.date`"
                            class="mb-0"
                        >
							<a-date-picker
								placeholder="eg. 2022-12-31"
								v-model="step4.addOnDeposits[i - 1].date"
								style="width: 100%;"
								valueFormat="YYYY/MM/DD"
							></a-date-picker>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="6">
                        <a-form-model-item
                            :prop="`addOnDeposits.${i - 1}.amount`"
                            class="mb-0"
                        >
                            <a-input-number
                                :min="0"
                                @change="checkBalance"
                                :formatter="
                                    (value) => '$ ' + formatPrice(value)
                                "
                                :parser="
                                    (value) => value.replace(/\$\s?|(,*)/g, '')
                                "
                                v-model="step4.addOnDeposits[i - 1].amount"
                                style="width: 100%"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="6">
                        <a-form-model-item
                            :label="`Balance due on ${closingDateString}`"
                            class="mt-3"
                        >
                            <a-input-number
                                class="w-full"
                                :formatter="
                                    (value) => '$ ' + formatPrice(value)
                                "
                                :parser="
                                    (value) => value.replace(/\$\s?|(,*)/g, '')
                                "
                                disabled
                                :value="step4.balanceDue"
                            />
                            <div
                                slot="extra"
                                class="text-danger"
                                v-if="step4.balanceDue < 0"
                            >
                                There might be an issue with the payment
                                structure. Please review.
                            </div>
                            <div
                                slot="extra"
                                class="text-danger"
                                v-else-if="addOnDepositClosingIssue"
                            >
                                <span
                                    v-if="addOnDepositClosingIssue === 'before'"
                                >
                                    A Deposit date cannot be later than the
                                    closing date. Please review.
                                </span>
                            </div>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-card>

            <a-card
                title="Dynamic Purchaser"
                v-if="
                    questions.choosePurchaser &&
                    transaction.purchasers.length > 1
                "
            >
                <a-form-model-item class="mb-0" label="Choose a purchaser">
                    <a-select v-model="step4.purchaserX">
                        <a-select-option
                            v-for="purchaser in transaction.purchasers"
                            :value="purchaser.id"
                            :key="purchaser.id"
                            >{{ purchaser.firstName }}
                            {{ purchaser.lastName }}</a-select-option
                        >
                    </a-select>
                </a-form-model-item>
            </a-card>

            <a-card
                title="Custom Fields"
                v-if="customFields.length"
                class="mt-3"
            >
                <a-form-model-item
                    v-for="item in customFields"
                    :help="item.help ? item.help : ''"
                    :key="'halkdsjf' + item.value"
                    :label="item.name || 'Unnamed Question'"
                    :val="`step4.customFields['${item.value}']`"
                >
                    <template v-if="item.type === 'text'">
                        <a-textarea
                            v-model="
                                step4.customFields[
                                    item.value.replace(
                                        'other.customFields.',
                                        ''
                                    )
                                ]
                            "
							:auto-size="{ minRows: 3, maxRows: 5 }"
                        />
                    </template>

                    <template v-else-if="item.type === 'checkmark'">
                        <a-switch
                            v-model="
                                step4.customFields[
                                    item.value.replace(
                                        'other.customFields.',
                                        ''
                                    )
                                ]
                            "
                        />
                    </template>
                    <template v-else-if="item.type === 'multiple'">
                        <a-select
                            :value="
                                step4.customFields[
                                    item.value.replace(
                                        'other.customFields.',
                                        ''
                                    )
                                ]
                            "
                            @change="
                                (e) =>
                                    selectMultiple(
                                        e,
                                        item.value.replace(
                                            'other.customFields.',
                                            ''
                                        )
                                    )
                            "
                        >
                            <a-select-option
                                v-for="opt in item.configure.options"
                                :key="opt.value"
                                :value="opt.value"
                                >{{ opt.value }}</a-select-option
                            >
                        </a-select>
                    </template>
                    <template v-else-if="item.type === 'image'">
                        <ImageBoxSelector
                            v-model="
                                step4.customFields[
                                    item.value.replace(
                                        'other.customFields.',
                                        ''
                                    )
                                ]
                            "
                        />
                    </template>
                    <template v-else-if="item.type === 'date'">
						<a-date-picker
							placeholder="Choose Date (eg. 2025-03-31)"
                            v-model="
                                step4.customFields[
                                    item.value.replace(
                                        'other.customFields.',
                                        ''
                                    )
                                ]
                            "
							:disabled-date="disabledDate"
							style="width: 100%;"
							valueFormat="YYYY/MM/DD"
						></a-date-picker>
                    </template>
					<template v-else-if="item.type === 'variable'">
						<div v-html="item.configure.defaultValue"></div>
						<div class="mt-3 px-3">
							<a-form-model-item :label="'Exclude Field from Contract'" style="margin-bottom: 0.5rem;">
								<a-switch @change="excludeField(item)" v-model="item.hide"></a-switch>
							</a-form-model-item>
							<a-form-model-item v-for="(variable, index) in step4.customFieldVariables[item.value.replace('other.customFields.', '')]" :label="variable.label || 'Unnamed Variable'" style="margin-bottom: 0.5rem;" :key="variable+index">
								<a-input :defaultValue="variable.value" @change="(e) => updateVariable(e, item.value.replace('other.customFields.', ''), index)" :disabled="item.hide" />
							</a-form-model-item>
						</div>
					</template>
                </a-form-model-item>
            </a-card>
        </a-form-model>
    </div>
</template>

<script>
import { generateContractPreview, isProductAvailable, getContractTemplateName } from "./tools";
import { transformTransaction } from "bh-mod/helper";
import moment from "moment";
import ImageBoxSelector from "bh-mod/components/common/ImageBoxSelector";
import { setProp, arrToObj } from "bh-mod";


export default {
    components: { ImageBoxSelector },
    props: ["transaction", "updateTransaction"],
    data: () => ({
        finalTransaction: {},
        envelopeFileKey: null,
        leaseQuestions: [],
        questions: {
            inclusions: false,
            addOnDeposits: 0,
            choosePurchaser: false,
        },
        customFields: {},
        step4: {
            premiums: [],
            lot: {},
            package: {
                parking: 0,
                locker: 0,
                lockerPrice: 0,
                parkingPrice: 0,
                bike: 0,
                bikePrice: 0,
                price: 0,
            },
            balanceDue: 0,
            addOnDeposits: [],
            customFields: {},
            inclusions: [],
            purchaserX: "",
            totalRent: 0,
            purchasePrice: 0,
            addOnsPrice: 0,
			addOnsRent:0,
			customFieldVariables: {}
        },
    }),
    computed: {
        unitPremiums() {
            let unitPremiums =
                (this.transaction.product.unit &&
                    this.transaction.product.unit.package.other &&
                    this.transaction.product.unit.package.other.premiums) ||
                {};
            let price = 0;
            Object.values(unitPremiums).forEach((prm) => {
                if (!prm.active) return;
                if (prm.value) prm.value = +prm.value;
                if (isNaN(prm.value)) return;
                price += prm.value;
            });
            return price;
        },
        pkgPrice() {
            return this.transaction.product.unit.package.price || 0;
        },
        msrp() {
            return this.pkgPrice + this.unitPremiums + (this.transaction.type == 'purchase' ? this.step4.addOnsPrice : this.step4.addOnsRent);
        },
        instance() {
            return this.$store.state.instance;
        },
        appSettings() {
            return (
                this.$store.state.appData.allSettings.app &&
                this.$store.state.appData.allSettings.app.options
            );
        },
        addOnDepositClosingIssue() {
            if (!this.step4.addOnDeposits.length) return false;
            let closing =
                moment(this.transaction.offer.closingDate).unix() * 1000;

            for (const i in this.step4.addOnDeposits) {
                if (this.step4.addOnDeposits.hasOwnProperty(i)) {
                    const dep = this.step4.addOnDeposits[i];
                    if (!dep.date) return false;
                    let date = moment(dep.date).unix() * 1000;
                    if (date > closing) return "before";
                }
            }
            return false;
        },
        closingDateString() {
            let closing = this.transaction.offer.closingDate;
            return moment(closing).isValid() ? moment(closing).format('MMM DD, YYYY') : ''
        },
        defaultAddOnDeposits() {
            if (!this.$store.getters.appSettings) return [];
            let dps =
                this.$store.getters.appSettings &&
                this.$store.getters.appSettings.options &&
                this.$store.getters.appSettings.options.addOnDeposits;
            return dps || [];
        },
        inclusions() {
            if (!this.$store.getters.appSettings) return [];
            let incs =
                this.$store.getters.appSettings &&
                this.$store.getters.appSettings.options &&
                this.$store.getters.appSettings.options.inclusions;
            let result = [];
            if (incs) result = Object.values(incs);
            return result.map((inc) => ({
                label: inc.title,
                value: inc.id,
            }));
        },
        currentContract() {
            if (!this.transaction.contract) return {};
            return this.$store.state.appData.templates[this.transaction.template] || {};
        },
    },
    watch: {
        "step4.addOnsPrice"(val) {
            if (this.questions.addOnDeposits) {
                this.checkBalance();
            }
        },
    },
    methods: {
		excludeField(item){
			if (item.hide){
				this.step4.customFields[item.value.replace('other.customFields.', '')] += '%hide%';
			} else {
				this.step4.customFields[item.value.replace('other.customFields.', '')] = this.step4.customFields[item.value.replace('other.customFields.', '')].replaceAll('%hide%', '');
			}
		},
		updateVariable(e, key, index){
			this.step4.customFieldVariables[key][index].value = e.target.value;
		},
		getVariableNames(input) {
			const matches = input.match(/{(.*?)}/g);
			return [...new Set(matches.map(match => match.slice(1, -1)))];
		},
        disabledDate(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
        calculateAddOnDeposits() {
            this.step4.addOnDeposits = [];
            let dD = this.defaultAddOnDeposits;
            let maxDeposit = this.questions.addOnDeposits;
            let pPrice = this.step4.addOnsPrice;
            let balance = this.step4.addOnsPrice;
            let lastDate = moment();

            for (let i = 0; i < maxDeposit; i++) {
                let amountToPay = 0;
                let date = "";
                if (dD[i]) {
                    date = moment(lastDate.toString());
                    let { date: dateObj, amount, changeOnAmount = {} } = dD[i];

                    let { perc, value } = amount;
                    let { days, value: dateValue } = dateObj;
                    //Figure out Amount
                    if (perc) {
                        let percAmount = (value / 100) * pPrice;
                        // console.table({value,balance})
                        if (percAmount <= balance) {
                            amountToPay = percAmount;
                        } else {
                            amountToPay = balance;
                        }
                    } else if (value <= balance) {
                        amountToPay = value;
                    }

                    let { value: changeValue, perc: changePerc } = changeOnAmount
                    if (changePerc) {
                        let percAmount = (changeValue / 100) * amountToPay
                        amountToPay = amountToPay + percAmount
                    } else if (changeValue) {
                        amountToPay = amountToPay + changeValue
                    }

                    if (days) {
                        date = date.add(dateValue, "days");
                    } else {
                        date = moment(dateValue);
                    }
                }
                balance = balance - amountToPay;

                if (date) date = date.format("YYYY-MM-DD");
                this.step4.addOnDeposits.push({
                    date,
                    amount: amountToPay,
                });
            }

            this.step4.balanceDue = balance;
        },
        calcPrice() {
            let sofar =
                Number(this.pkgPrice || 0) +
                Number((this.transaction.type == 'purchase' ? this.step4.addOnsPrice : this.step4.addOnsRent) || 0);

            let premiums = Object.values(this.step4.premiums).reduce(
                (acc, cur) => Number(acc || 0) + (cur.value || cur.price),
                0
            );
            return sofar + premiums;
        },
        calculateTotalRent() {
            this.step4.totalRent = this.calcPrice();
        },
        calculateTotalPurchase() {
            this.step4.purchasePrice = this.calcPrice();
        },
        req: (msg) => ({ required: true, message: msg }),

        async createEnvelope() {
            this.$store.commit("LOAD", true);
            let transaction = this.finalTransaction;

            transaction.envelope = {
                name: getContractTemplateName(transaction, this.instance.productType) || this.currentContract.name,
                isManual: true,
                file: {
                    template: this.currentContract.id,
                    key: this.envelopeFileKey,
                },
            };

            if (transaction.type === "lease") {
                transaction.price = this.step4.totalRent;
            } else if (this.step4.purchasePrice) {
                transaction.price = this.step4.purchasePrice;
            }

            this.$api
                .put(`/transactions/:instance/${transaction.id}`, transaction)
                .then((resp) => {
                    if (resp && resp.data && resp.data.id) {
                        let tr = resp.data;
                        this.$store.commit("SET_TRANSACTION", {
                            id: tr.id,
                            value: tr,
                        });
                        this.$store.commit(
                            "SET_CURRENT_TRANSACTION",
                            resp.data
                        );
                        this.$message.success(
                            "Transaction Updated. Please remember to download and print"
                        );
                        this.$emit("close");
                    } else {
                        this.$message.error(
                            "An error occurred while updating your transaction. Please try again."
                        );
                    }
                })
                .catch((err) => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, 'An error occurred while updating your transaction. Please try again.'))
					}
                })
                .finally(() => {
                    this.$store.commit("LOAD", false);
                    this.$store.commit("CLOSE_PREVIEW");
                });
        },
        async generatePreview() {
            const transaction = this.finalTransaction;
            this.$store.commit("LOAD", true);
            if (!isProductAvailable(transaction, this)) {
                if (this.instance.productType == "lowrise") {
                    this.$message.error(
                        "Sorry this lot is no longer available. Please select another one."
                    );
                } else {
                    this.$message.error(
                        "Sorry this unit is no longer available. Please select another one."
                    );
                }
                return;
            }

            try {
                const { key, url } = await generateContractPreview(
                    transaction,
                    this
                );
                this.envelopeFileKey = key;
                this.$store.commit("REVIEW_SEND", {
                    preview: url,
                    callback: this.createEnvelope,
                    button: "DOWNLOAD",
                    isManual: true,
                });
            } catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, 'An error occurred while generating your contract. Please try again.'))
				}
            }

            this.$store.commit("LOAD", false);
        },
        selectMultiple(e, i) {
            this.step4.customFields[i] = e;
        },

        checkBalance() {
            let pPrice = this.step4.addOnsPrice;
            let amount = 0;

            this.step4.addOnDeposits.forEach((dep) => {
                amount += dep.amount;
            });

            this.step4.balanceDue = pPrice - amount;
        },
        formatPrice: (p) => {
            let isDot = false;
            if (typeof p === "string") {
                if (isNaN(+p[p.length - 1])) {
                    if (p[p.length - 1] === ".") {
                        isDot = true;
                    }
                }
                if (p[p.length - 4] === ".") {
                    p = p.split("");
                    p.pop();
                    p = p.join("");
                }
                p = parseFloat(parseFloat(p).toFixed(2));
            }
            if (!p) return `0`;
            let result = p.toLocaleString("en-us");
            if (isDot) result = result + ".";
            return result;
        },
        validate() {
            if (this.depositClosingIssue || this.step4.balanceDue < 0)
                return this.$message.error("Please review the fields");

            this.calculateTotalPurchase();
            let {
                addOnDeposits = [],
                purchasePrice,
                addOnsPrice,
				addOnsRent,
                customFields = {},
                inclusions = [],
                package: pkg,
                premiums,
				customFieldVariables,
                purchaserX = "",
            } = this.step4;

            premiums.forEach((prem) => {
                pkg.other.premiums[prem.id] = prem;
            });

            this.movedOn = true;

            if (this.transaction && this.transaction.type === "purchase") {
                this.transaction.offer.purchasePrice = this.step4.purchasePrice;
            }
            this.transaction.offer.addOnsPrice = this.step4.addOnsPrice;
            this.transaction.offer.addOnsRent = this.step4.addOnsRent;

            if (this.transaction.isManual) {
                let transaction = JSON.parse(JSON.stringify(this.transaction));

                if (
                    this.$store.state.appData.allSettings &&
                    this.$store.state.appData.allSettings.app &&
                    this.$store.state.appData.allSettings.app.options
                ) {
                    transaction.other.defaults =
                        this.$store.state.appData.allSettings.app.options;
                }

                transaction.offer.date = Date.now();

                transformTransaction(transaction);
                if (this.step4.inclusions && this.step4.inclusions.length) {
                    let incs = arrToObj(this.appSettings.inclusions);
                    transaction.offer.inclusions = this.step4.inclusions.map(
                        (incId) => {
                            if (incs[incId]) {
                                return incs[incId].body || "";
                            }
                            return "";
                        }
                    );
                }

                let {
                    package: pkg,
                    addOnDeposits = [],
                    purchasePrice,
                    addOnsPrice,
                    addOnsRent,
                } = this.step4;

                transaction.offer.basePrice = pkg.price;
                transaction.offer.purchasePrice = purchasePrice;
                transaction.offer.addOnsPrice = addOnsPrice;
                transaction.offer.addOnsRent = addOnsRent;
                transaction.offer.addOnDeposits = addOnDeposits;
                transaction.product.unit.package = JSON.parse(
                    JSON.stringify(pkg)
                );
                transaction.other.purchaserX = purchaserX;
                transaction.other.customFields = customFields;
				transaction.other.customFieldVariables = customFieldVariables

                if (transaction.type === "purchase") {
                    transaction.offer.balanceDue = this.step4.balanceDue;
                }

                this.finalTransaction = transaction;
                this.generatePreview();
            } else {
                let sendObj = {
                    package: pkg,
                    offer: {
                        ...this.transaction.offer,
                        addOnDeposits,
                        inclusions,
                        balanceDue: this.step4.balanceDue,
                        purchasePrice,
                        addOnsPrice,
						addOnsRent,
                    },
                    other: {
                        ...this.transaction.other,
                        purchaserX,
                        customFields,
						customFieldVariables
                    },
                };
                this.$emit("done", sendObj);
            }
        },
    },
    mounted() {
        this.$emit("setFn", this.validate);

        let cF = this.currentContract.customFields || {};
        let customFields = {};
        let { pages = [] } = this.currentContract;
        let maxPurchasers = 0;

        pages.forEach((page) => {
            let { fields = [] } = page;
            fields.forEach((spot) => {
                let { field, text } = spot;
                if (
                    field.custom &&
                    cF[field.value] &&
                    !customFields[field.value]
                ) {
                    customFields[field.value] = cF[field.value];

                    let fieldKey = field.value.replace(
                        "other.customFields.",
                        ""
                    );

					if (field.type == 'variable' && cF[field.value]?.configure?.defaultValue != '') {
						let variables = this.getVariableNames(cF[field.value]?.configure?.defaultValue)
						variables = variables.map((x,xI) => {
							const value = cF[field.value]?.configure?.options[xI]?.value || "";
							return {
								label: x,
								value: value
							}
						})
						setProp(this.step4.customFieldVariables, [fieldKey], variables)
					}
                    if (
                        field.type == "multiple" &&
                        cF[field.value].configure.defaultValue != ""
                    ) {
                        setProp(
                            this.step4.customFields,
                            [fieldKey],
                            cF[field.value].configure.options[
                                parseInt(cF[field.value].configure.defaultValue)
                            ].value
                        );
                    } else {
                        setProp(
                            this.step4.customFields,
                            [fieldKey],
                            cF[field.value].configure.defaultValue
                        );
                    }
                }

                if (
                    field.deposit &&
                    text.addOnDeposits &&
                    text.addOnDeposits > this.questions.addOnDeposits
                ) {
                    this.questions.addOnDeposits = text.addOnDeposits;
                }

                if (field.inclusions && !this.questions.inclusions) {
                    this.questions.inclusions = true;
                }

                if (
                    field.value.includes("purchaserX") &&
                    !this.questions.choosePurchaser
                ) {
                    this.questions.choosePurchaser = true;
                }
                if (
                    field.value.includes("purchasers[]") &&
                    text.purchasers > maxPurchasers
                ) {
                    maxPurchasers = text.purchasers;
                }
            });
        });

        this.customFields = Object.values(customFields);
        this.transaction.other.purchasersInContract = maxPurchasers;

        setTimeout(() => {
            if (this.transaction.type == "purchase") {
                this.calculateTotalPurchase();
                this.calculateAddOnDeposits();
            } else {
                this.calculateTotalRent();
            }
        }, 1000);
    },
    created() {
        if (this.updateTransaction) {
            let {
                offer = {
                    addOnDeposits: [],
                    inclusions: [],
                    balanceDue: 0,
                },
                other = {
                    purchaserX: "",
                    customFields: {},
					customFieldVariables: {}
                },
            } = this.transaction;
            let {
                addOnDeposits = [],
                inclusions = [],
                balanceDue = 0,
                purchasePrice,
                addOnsPrice,
				addOnsRent
            } = offer;
            let { customFields, customFieldVariables, purchaserX } = other;

            this.step4.package = JSON.parse(
                JSON.stringify(this.transaction.product.unit.package)
            );
            this.step4.premiums = JSON.parse(
                JSON.stringify(
                    Object.values(
                        this.transaction.product.unit.package.other.premiums
                    )
                )
            );

            this.step4.balanceDue = balanceDue;
            this.step4.addOnDeposits = addOnDeposits;
            this.step4.customFields = customFields;
			this.step4.customFieldVariables = customFieldVariables;
            this.step4.inclusions = inclusions;
            this.step4.purchaserX = purchaserX;
            this.step4.purchasePrice = purchasePrice;
            this.step4.addOnsPrice = addOnsPrice;
            this.step4.addOnsRent = addOnsRent;
            this.step4.price = this.pkgPrice;
        }
    },
};
</script>

<style>
</style>
